@import 'styles/utils/colors';
@import 'styles/utils/mixins';
@import 'styles/utils/sizes';

.section {
  padding: 120px 0 60px;

  .section-title {
    [data-title],
    [data-subtitle] {
      @include fontSize(48px, 120%);

      margin: 0;
      font-weight: 600;

      @include phone {
        @include fontSize(24px, 150%);
      }
    }

    [data-subtitle] {
      color: $gray500;
    }
  }

  .container {
    @include flex(row, space-between, flex-start);

    gap: 32px;
    margin: 0 auto;
    padding: 0 32px;
    max-width: $wide-content-width;

    @include tablet {
      @include flex(column);
    }

    @include phone {
      padding: 0 16px;
    }
  }

  .left {
    position: sticky;
    z-index: 1;
    top: calc($header-height + 32px);
    max-width: 276px;
    width: 100%;
    background-color: $gray0;

    @include tablet {
      top: $header-height;
      padding-bottom: 16px;
      max-width: 100%;
    }

    @include phone {
      top: 65px;
    }
  }

  .right {
    @include flex(column);

    gap: 16px;
    width: 100%;
  }

  .stepper {
    @include flex(column);

    position: relative;
    gap: 4px;

    @include tablet {
      @include flex(row, space-between);
    }

    @include phone {
      @include flex(column);
    }
  }

  .step {
    @include flex(row, flex-start, center);

    z-index: 1;
    gap: 16px;
    width: 100%;
    padding: 4px;
    cursor: pointer;
    color: rgba($gray800, 0.64);
    border-radius: 16px;
    transition: all 0.3s ease-in-out;

    span {
      @include flex(row, center, center);
      @include fontSize(20px, 150%);

      width: 48px;
      height: 48px;
      color: currentcolor;
      flex-shrink: 0;
      background-color: $gray100;
      border-radius: 12px;
      white-space: nowrap;
      transition: all 0.3s ease-in-out;
    }

    &.active {
      color: $gray900;

      span {
        background-color: $yellow300;
      }

      &:hover {
        background-color: transparent;
      }
    }

    &:hover {
      background-color: $gray50;
    }
  }

  .overlay {
    position: absolute;
    z-index: 0;
    padding: 4px;
    width: 100%;
    height: 56px;
    background-color: $yellow200;
    border-radius: 16px;
    transition: all 0.3s ease-in-out;
  }

  .item {
    @include flex(row);

    scroll-margin-block: calc($header-height + 32px);

    @include below-tablet {
      @include flex(column);
    }
  }

  .item-left {
    flex: 0 0 50%;
    width: 100%;
    height: 322px;
    overflow: hidden;
    background-color: $gray50;
    border-radius: 16px;

    @include below-tablet {
      flex: none;
      height: 257px;
    }
  }

  .item-right {
    @include flex(column, center, flex-start);

    flex: 0 0 50%;
    gap: 8px;
    width: 100%;
    padding-left: 32px;

    @include below-tablet {
      flex: none;
      padding-left: 0;
      padding-top: 16px;
    }
  }

  .item-image {
    width: 100%;
    height: 100%;
  }

  .item-title {
    @include fontSize(20px, 150%);

    font-weight: 600;
  }

  .item-text p {
    @include fontSize(16px, 150%);

    color: $black-alpha;
  }

  @include phone {
    padding: 60px 0 30px;
  }
}
