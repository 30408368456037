@import 'styles/utils/mixins';

.h2 {
  @include fontSize(35px);

  font-weight: 600;
  margin: 80px 0 40px;
  line-height: 1.3;

  @include tablet {
    @include fontSize(30px);
  }

  @include phone {
    @include fontSize(18px);

    margin: 60px 0 30px;
    line-height: 1.5;
  }
}

.h3 {
  @include fontSize(25px);

  font-weight: 600;
  margin: 45px 0 20px;
  line-height: 1.3;

  @include tablet {
    @include fontSize(20px);
  }

  @include phone {
    @include fontSize(15px);

    margin: 35px 0 15px;
    line-height: 1.5;
  }
}

.paragraph {
  @include fontSize(19px);

  @include macbook-fifteen {
    @include fontSize(18px);
  }

  line-height: 1.6;
  margin: 0 0 40px;
  padding: 0;
  color: $tundora;

  @include macbook-twelve {
    @include fontSize(16px);
  }

  @include tablet {
    @include fontSize(17px);

    margin-bottom: 20px;
  }

  @include phone {
    @include fontSize(14px);
  }
}

.a {
  text-decoration: none;
  color: $mirage;
  border-bottom: 2px solid $turbo-100;
  transition: border-bottom-color 0.2s ease;

  &:hover {
    border-bottom-color: $mirage;
  }
}

.ul {
  @include fontSize(19px);

  @include macbook-fifteen {
    @include fontSize(18px);
  }

  padding-left: 15px;
  margin-right: 0;
  margin-left: 15px;
  list-style: disc outside;
  line-height: 1.6;

  & > li {
    margin-bottom: 10px;
  }

  @include macbook-twelve {
    @include fontSize(16px);
  }

  @include tablet {
    @include fontSize(17px);

    margin-bottom: 20px;
  }

  @include phone {
    @include fontSize(14px);
  }
}

.ol {
  @include fontSize(19px);

  @include macbook-fifteen {
    @include fontSize(18px);
  }

  list-style: decimal outside;
  padding-left: 20px;
  margin: 0 0 40px 15px;
  line-height: 1.6;

  & > li {
    margin-bottom: 10px;
  }

  @include macbook-twelve {
    @include fontSize(16px);
  }

  @include tablet {
    @include fontSize(17px);

    margin-bottom: 20px;
  }

  @include phone {
    @include fontSize(14px);
  }
}

.quote {
  @include fontSize(40px);

  @include macbook-fifteen {
    @include fontSize(35px);

    line-height: 1.5;
  }

  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding-left: 55px;
  font-weight: 500;
  margin: 75px 0;
  line-height: 1.3;
  color: $mirage;

  &::before {
    position: absolute;
    left: -5px;
    content: url('Article/images/bracket.svg');

    @include below-tablet {
      transform: scale(0.8);
    }

    @include phone {
      transform: scale(0.9);
    }
  }

  a {
    text-decoration: none;
    color: $mine-shaft;
    background-image: linear-gradient($turbo-100, $turbo-100);
    background-position: 0 100%;
    background-repeat: no-repeat;
    background-size: 70% 8px;
    transition: background-size 0.6s ease-out;

    &:hover {
      transition: background-size 0.6s ease-out;
      background-size: 100% 8px;
    }
  }

  @include tablet {
    @include fontSize(25px);

    margin: 40px 0;
    padding-left: 45px;
  }

  @include phone {
    @include fontSize(20px);

    line-height: 1.4;
  }
}
