@import "styles/utils/mixins";
@import "styles/utils/colors";
@import "styles/utils/sizes";

.image {
  position: relative;
  display: block;

  img {
    object-fit: contain;
    background-size: contain !important;
    background-image: none !important;
  }
}

.video-container {
  position: relative;
}

.play-button {
  @include flex(row, center, center);

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 64px;
  height: 64px;
  background-color: rgba($gray0, 0.32);
  border: none;
  border-radius: 50%;
  outline: none;
  cursor: pointer;

  &:active {
    svg {
      transform: scale(1.1);
      transition: all 0.3s ease-in-out;
    }
  }
}
